<div class="bk-dialog bk-dialog__event">
	
	<button type="button"
		class="bk-dialog__close"
		(click)="cancel()">
		&times;
	</button>

	<h4 mat-dialog-title>
		{{ 'Soil Water Balance' | translate }}
	</h4>
	<h5 class='bk-modal__subTitle'>
		{{ plantingName }}
	</h5>

	<div mat-dialog-content>
		<mat-tab-group
			class="bk-available-water-chart"
			(selectedTabChange)="redrawGraph($event)">
			<mat-tab label="Soil Water Balance">
				<div class="bk-chart" #chartContainer></div>
				
				<p *ngIf="isLoaded">
					{{ 'Accuracy instruction text' | translate }}.
					<span class="cm-learn-more-link" (click)="onCustomSaturationHelpOpen()">{{ 'Learn More' | translate }}</span>
				</p>
				<form *ngIf="form"
					[formGroup]=form
					class="bk-form cm-saturation-date-form">
					
					<div class="bk-custom-saturation-ui">
						<mat-form-field appearance="fill"
							class='mat-form-field--short'>
							<mat-label>Start Date</mat-label>
							<input matInput [matDatepicker]="picker"
								formControlName="StartDate"
								[min]="minDate"
								[max]="maxDate">
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
							<mat-error *ngIf="f.StartDate.invalid && (f.StartDate.dirty || f.StartDate.touched)">
								Initial saturation date is invalid
							</mat-error>
						</mat-form-field>

						<div class="bk-custom-saturation-ui__slider-section">
							<mat-slider
								min="0" max="100"
								aria-label="unit(s)"
								thumbLabel
								[displayWith]="formatLabel"
								formControlName="InitialSaturation"></mat-slider>
							<div class="bk-custom-saturation-ui__legend">
								<span class="bk-legend--end">{{ 'Dry' | translate }}</span>
								<span class="bk-legend-middle">{{ 'Initial Available Water Percentage' | translate }}</span>
								<span class="bk-legend--end">{{ 'Wet' | translate }}</span>
							</div>
						</div>

						<button mat-button
							[disabled]="!form.dirty || form.invalid"
							class="bk-button bk-button--medium bk-button--text-only bk-button--same-line"
							style="top: -12px;"
							(click)="onUpdate()">
							{{ 'Recalculate' | translate }}
							<img class='bk-button__loader' *ngIf='!isLoaded' src='/assets/images/loader-20px.gif'/>
						</button>
					</div>

					
				</form>
			</mat-tab>
			<mat-tab label="ET">
				<div class="bk-chart" #etChartContainer></div>

				<div *ngIf="isLoaded"
					class="bk-chart__explanation">

					<h2 class="bk-chart__explanation__header">
						<mat-icon>
							info_outline
						</mat-icon>
						How to read this chart
					</h2>
					<p><em>ETc</em> line represents the crop specific evapotranspiration (ET) over time, which is the reference ET (ETo)
						multiplied by a crop's crop coefficient (Kc). <em>ETe</em> line is ETo modified by effects of soil evaporation.
					</p>
				</div>
			</mat-tab>

			<ng-container *ngIf="isAdmin">
				<mat-tab label="Crop Coefficient">
					<div class="bk-chart" #kcChartContainer></div>

					<div *ngIf="isLoaded"
						class="bk-chart__explanation">

						<h2 class="bk-chart__explanation__header">
							<mat-icon>
								info_outline
							</mat-icon>
							How to read this chart
						</h2>
						<p><em>Kc</em> line tracks your crop's crop coefficient over time, which is multiplied against reference ET to
							calculate ETc.
						</p>
					</div>
				</mat-tab>
			</ng-container>
			<mat-tab label="Crop Stress">
				<div class="bk-chart" #ksChartContainer></div>

				<div *ngIf="isLoaded"
					class="bk-chart__explanation">

					<h2 class="bk-chart__explanation__header">
						<mat-icon>
							info_outline
						</mat-icon>
						How to read this chart
					</h2>
					<p><em>Ks</em> line tracks your crop's stress over time
					</p>
				</div>
			</mat-tab>
			<ng-container *ngIf="isAdmin">
				<mat-tab label="Root Depth">
					<div class="bk-chart" #rootDepthChartContainer></div>
					<div *ngIf="isLoaded"
						class="bk-chart__explanation">

						<h2 class="bk-chart__explanation__header">
							<mat-icon>
								info_outline
							</mat-icon>
							How to read this chart
						</h2>
						<p><em>Root depth</em> line tracks your crop's root depth over time. A plant's available water is impacted
							by the root depth. As the root depth increases, more water is available to the plant.
						</p>
					</div>
				</mat-tab>
			</ng-container>
		</mat-tab-group>
		<!-- saturation controls -->
	</div>

	<div mat-dialog-actions align="end">

		<button (click)="cancel()" mat-button
			class="bk-button--medium bk-dialog__button--primary">
			{{ 'Close' | translate }}
		</button>
	</div>
</div>