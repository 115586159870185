<div class="main-menu-container bk-menu__container" [hidden]="!visible">
    <div class="bk-menu" (clickOutside)="clickOutside($event)">
        <div class="bk-menu__header">
            <span class="bk-menu__close bk-clickable" (click)="close()">&times;</span>
            <div class="bk-menu__header__logo"></div>
        </div>
        <ul class="bk-menu__list">
            <li class="bk-menu__item"
                [ngClass]="{ 'bk-menu__item--selected': currentPage == 'MyDashboard' }"
                (click)="navigateToMyRanches()">
                <div class="cm-svg-icon cm-svg-icon--white cm-svg-icon--thick">
                    <svg-ranch-marker></svg-ranch-marker>
                </div>
                {{'My Ranches' | translate }}
            </li>
            <li *ngIf="user && user.Roles && user.Roles[0] && user.Roles[0].Id == 1"
                class="bk-menu__item"
                [ngClass]="{ 'bk-menu__item--selected': currentPage == 'Administration' }"
                (click)="navigateToAdministration()">
                <mat-icon style="margin-top:-3px;" fontSet="material-icons-outlined">admin_panel_settings</mat-icon>
                {{'Admin' | translate }}
            </li>
            <li class="bk-menu__item"
                [ngClass]="{ 'bk-menu__item--selected': currentPage == 'Profile' }"
                (click)="navigateToProfile()">
                <mat-icon  style="margin-top:-3px;" fontSet="material-icons-outlined">person_outline</mat-icon>
                {{'Profile' | translate }}
            </li>
            <li class="bk-menu__divider"></li>
            <li>
                <a class="bk-menu__item"
                    href="{{ donationLink }}"
                    target="_blank">
                    <mat-icon fontSet="material-icons-outlined">volunteer_activism</mat-icon>
                    {{'Donate' | translate }}
                </a>
            </li>
            <li>
                <a class="bk-menu__item"
                href="https://discord.gg/NxFruVBtDW"
                target="_blank">
                    <mat-icon fontSet="material-icons-outlined">group</mat-icon>
                    {{'Join Our Discord' | translate }}
                </a>
            </li>
            <li class="bk-menu__item"
                (click)="logout()">
                <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
                {{'Log Out' | translate }}
            </li>
        </ul>
    </div>
</div>