import { IPlantingAdvancedEdit } from '../../components/planting-settings/interfaces';
import { SuccessResponse } from '../../interfaces/interfaces';

export enum YieldUnitId {
	LBS = 1,
	TONS = 2,
};

/**
 * = Common.Models.Json.CommodityType
 */
export interface ICommodityType {
	Id: number,
	Name: string,
	HarvestOnce: boolean,
	IconPath: string,
	HeaderBackgroundPath?: string,
	IsAssociatedWithRanch?: boolean,
	IsActive: boolean,
	CommodityTypeCalculator: string,
	YieldUnitId: YieldUnitId,

	// virtual

	Selected?: boolean, // used for displaying plantings filters
	Disabled?: boolean,
}

/**
 * Used for displaying a commodity type dropdown on admin/crop stages view
 */
export interface ICommodityTypeCropStageDropDownItem {
	Id: number,
	Name: string,
	CropStagesCount?: number, // number of crop stages associated
	IsSelected?: boolean,
}

export interface ICommodityTypesRanchJSON {
	Id: number,
	RanchId: number,
	CommodityTypeId: number,
	Name: string,
	IsActive: boolean,
	PlantingsCount: number
}

export interface ICommodityTypesRanchPostResponseViewModel {
	CommodityTypesRanch: ICommodityTypesRanchJSON[],
	CommodityTypes: ICommodityType[],
	Count: number
}

export interface ICommodityTypeDialogInput {
	ranchGuid: string,
	commodityTypeId?: number,
	commodityTypeName?: string,
	plantingsCount?: number
}

export const eCommodityTypeCalculators = {
	processingTomato: 'CropManage.Calculations.Calculators.ProcessingTomato',
	almond: 'CropManage.Calculations.Calculators.Almond',
	strawberry: 'CropManage.Calculations.Calculators.Strawberry',
	caneberry: 'CropManage.Calculations.Calculators.Caneberry',
	lettuce: 'CropManage.Calculations.Calculators.Lettuce',
	alfalfa: 'CropManage.Calculations.Calculators.Alfalfa',
	walnut: 'CropManage.Calculations.Calculators.Walnut',
	pistachio: 'CropManage.Calculations.Calculators.Pistachio'
}

export interface ICommodityService {

	listByRanch(ranchGuid: string): Promise<ICommodityTypesRanchPostResponseViewModel>,
	listAllByRanch(ranchGuid: string): Promise<ICommodityType[]>,

	saveByRanch(ranchGuid: string, commodityTypeId: number, cropTypeIds: number[])
		: Promise<SuccessResponse>,

	deleteByRanch(ranchGuid: string, commodityTypeId: number):
		Promise<ICommodityTypesRanchPostResponseViewModel>,

	list(): Promise<ICommodityType[]>,
}
