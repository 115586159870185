import { Component } from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'svg-ranch-marker',
	template: `
		<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2010 5100 c-50 -28 -1950 -1718 -1977 -1759 l-23 -34 0 -434 c0
-412 1 -436 20 -472 20 -41 85 -81 128 -81 36 0 87 25 131 65 l41 37 0 -1151
c0 -1135 0 -1152 20 -1191 13 -26 34 -47 60 -60 39 -20 54 -20 2310 -20 2256
0 2271 0 2310 20 26 13 47 34 60 60 20 38 20 57 20 917 l0 878 -23 39 c-12 22
-37 47 -57 57 -19 9 -297 66 -617 125 l-583 109 0 109 0 108 41 -37 c44 -40
95 -65 131 -65 42 0 103 38 126 79 22 38 22 45 22 470 0 350 -3 437 -14 459
-8 15 -40 52 -72 82 -151 142 -1890 1677 -1917 1693 -41 22 -93 21 -137 -3z
m951 -1111 c484 -430 883 -787 885 -793 2 -6 3 -97 2 -201 l-3 -189 -830 728
c-456 401 -843 737 -860 747 -30 19 -97 25 -131 12 -10 -4 -396 -338 -858
-742 -462 -405 -844 -737 -848 -739 -5 -2 -8 84 -8 192 l0 195 268 238 c147
131 502 445 787 698 286 253 563 499 615 547 52 48 96 87 97 87 2 1 399 -351
884 -780z m-152 -674 l721 -630 0 -227 0 -228 -1450 0 -1450 0 2 228 3 229
720 631 c396 347 723 630 727 629 4 -1 331 -285 727 -632z m721 -2200 l0 -815
-170 0 -170 0 0 585 c0 567 -1 587 -20 625 -13 26 -34 47 -60 60 -39 20 -57
20 -1030 20 -973 0 -991 0 -1030 -20 -26 -13 -47 -34 -60 -60 -19 -38 -20 -58
-20 -625 l0 -585 -170 0 -170 0 0 815 0 815 1450 0 1450 0 0 -815z m808 689
l472 -88 0 -708 0 -708 -490 0 -490 0 0 801 c0 757 1 800 18 795 9 -2 230 -44
490 -92z m-1798 -517 c0 -2 -103 -73 -230 -157 l-230 -153 -201 134 c-110 73
-215 143 -232 156 l-31 23 462 0 c254 0 462 -2 462 -3z m-739 -495 c-3 -4
-123 -87 -268 -184 l-263 -177 0 364 0 364 268 -180 c147 -99 265 -183 263
-187z m1089 4 l0 -363 -264 176 c-144 97 -263 181 -263 186 0 7 488 346 525
364 1 0 2 -163 2 -363z m-576 -338 l229 -153 -232 -3 c-127 -1 -335 -1 -462 0
l-231 3 228 153 c126 83 231 152 234 152 2 0 108 -69 234 -152z"/>
<path d="M2037 3345 c-21 -7 -48 -21 -59 -32 -44 -39 -48 -66 -48 -363 0 -262
1 -283 20 -320 23 -45 80 -80 130 -80 50 0 107 35 130 81 20 38 21 54 18 334
-3 280 -4 295 -24 322 -42 56 -108 79 -167 58z"/>
</g>
</svg>
    `,
})

export class SVGRanchMarkerComponent {

}
