import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { HttpErrorDialog } from '../components/server-error-dialog/http-error-dialog';
import { UpdateService } from './update.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CMError } from '../interfaces/interfaces';
import { Token } from './interface';
import { TokenService } from './token.service';
import { ReleaseVersion, VersionUpgradeCookieName } from './constants';
import { CookieService } from './cookie.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ErrorService {

	private urls = {
		sendEmail: '/v2/error-alert.json'
	};

	constructor(public dialog: MatDialog,
		private http: HttpClient,
		private _tokenService: TokenService,
		private updateService: UpdateService) { }

	public handleError(error: Error) {
		this.logError(error);
	}

	private logError(error: Error) {
		const chunkFailedMessage = /Loading chunk [^\s]+ failed/;

		if (!error) {
			return;
		}

		if (error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') >= 0) {
			return;
		}

		if (chunkFailedMessage.test(error.message)) {
			CookieService.set(VersionUpgradeCookieName, 'yes', 1000000);
			window.location.reload();
			// automatically reload the page if chunk
			// loading fails due to bad versioning

			return;
		}

		if (console && console.group && console.error) {
			console.group('Error Service - Console Log');
			console.warn(error.message);
			console.warn(error.stack);
			console.groupEnd();
			this.emailError(error);
		}
	}

	public handleHttpError(url: string, message: string): void {

		this.updateService.closeModal();

		this.dialog.open(HttpErrorDialog, {
			width: '250px',
			data: {
				errorMessage: message
			}
		});
	}

	public emailErrorMessage(message: string): Promise<CMError> {
		let headers: HttpHeaders;
		let token: Token;
		let body: { Message: string};

		token = this._tokenService.getToken();

		if (!token) {
			throw new Error('token is empty!');
		}

		headers = new HttpHeaders({ // Disable caching. Otherwise, ajax calls via Angular are cached prevents views in IE11 from refreshing
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache',
			'Authorization': 'Bearer ' + token.token,
			'X-Client-Version': ReleaseVersion
		});

		body = {
			Message: message
		}

		if (!this.http) {
			console.warn('ErrorService:emailError() - http is empty');
			return;
		}

		// using http here to avoid circular reference caused by httpService
		return this.http.post(environment.APIEndpoint + this.urls.sendEmail, body, { headers: headers}).
		toPromise().then((response: CMError): CMError => {
				return response;
			}
		);
	}

	private emailError(error: Error): Promise<CMError> {
		let headers: HttpHeaders;
		let token: Token;
		let body: { Message: string};

		if (!error || !error.message) {
			return;
		}

		token = this._tokenService.getToken();

		if (!token) {
			throw new Error('token is empty!');
		}

		headers = new HttpHeaders({ // Disable caching. Otherwise, ajax calls via Angular are cached prevents views in IE11 from refreshing
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache',
			'Authorization': 'Bearer ' + token.token,
			'X-Client-Version': ReleaseVersion
		});

		body = {
			Message: error.message + ' ' + error.stack
		}

		if (!this.http) {
			console.warn('ErrorService:emailError() - http is empty');
			return;
		}

		// using http here to avoid circular reference caused by httpService
		return this.http.post(environment.APIEndpoint + this.urls.sendEmail, body, { headers: headers}).
			toPromise().then(
				(response: CMError): CMError => {
					return response;
				}
			);
	}
}
