import { Injectable } from '@angular/core';

import { ModelService } from '../../services/model.service';
import { HttpParams } from '@angular/common/http';
import { IFlowWateringViewModel, IFlowMeterChartModel,
	IFlowMeterPlantingListViewModel, IFlowmeterSummaryViewModel, FlowmeterSettings } from './interfaces';
import { CMError, SuccessResponse } from '../../interfaces/interfaces';
import { FlowmeterCache } from './flowMeter';
import { HttpService, HttpServicePostOptions } from '../../services/http.service';
import { ValidateService } from '../../services/validate.service';
import { of } from 'rxjs';
import * as moment from 'moment';
import { IFlowMeterTableRow } from '../../modules/flowmeter/interfaces';
import { DateUtility } from '../../classes/dateUtility';
import { ObjectUtility } from '../../classes/objectUtility';


@Injectable()
export class FlowmeterService extends ModelService {

	private _urls = {
		settings: {
			get: (plantingId: number) => `/v0/plantings/${plantingId}/flowmeter/settings.json`,
			update: (plantingId: number) => `/v0/plantings/${plantingId}/flowmeter/settings.json`
		},
		summary: (plantingId: number) => `/v2/plantings/${plantingId}/flow-data/summary.json`,
		detail: (flowWateringId: number) => `/v2/flow-data/${flowWateringId}.json`,
		chart: (plantingId: number) => `/v2/plantings/${plantingId}/flow-data/chart.json`,
		flowWatering: (flowWateringId: number) => `/v2/flow-waterings/${flowWateringId}.json`,
		files: `/v2/flow-data/files.json`,
		import: (plantingId: number) => `/v2/plantings/${plantingId}/flow-data/import.json`,
		isFilenameValid: `/v0/flow-data/is-filename-valid.json`,
	};

	private _cache: FlowmeterCache;

	constructor(
		private _httpService: HttpService,
		private _validateService: ValidateService) {

		super(_httpService, _validateService);

		this._cache = {
			summary: null,
			details: new Array()
		};
	}

	/**
	 * Gets flowmeter graph data
	 * @param plantingId
	 */
	public getChart(plantingId: number): Promise<IFlowMeterChartModel> {

		return this.get(this._urls.chart(plantingId),
			(json: IFlowMeterChartModel) => {
				if (!this._cache.summary) {
					this._cache.summary = new Array();
				}

				this._cache.summary[plantingId] = jQuery.extend(true, {}, json);

				return json
			});
	}

	/**
	 * Get flow water detail data
	 * @param flowWateringId
	 */
	public getFlowWateringDetail(flowWateringId: number): Promise<IFlowMeterChartModel> {

		if (this._cache.details && this._cache.details[flowWateringId]) {
			return of(this._cache.details[flowWateringId]).toPromise();
		}

		return this.get(this._urls.flowWatering(flowWateringId),
		(json: IFlowMeterChartModel): IFlowMeterChartModel => {

			if (!this._cache.details) {
				this._cache.details = new Array();
			}

			this._cache.details[flowWateringId] = ObjectUtility.copy(json);

			return json
		});
	}

	public getFlowmeterSummary(plantingId: number): Promise<IFlowmeterSummaryViewModel[] | CMError> {

		return this.get(this._urls.summary(plantingId), (data: IFlowmeterSummaryViewModel[] | CMError):
			IFlowmeterSummaryViewModel[] | CMError  => {

			if (data) {
				return data;
			} else {
				return [];
			}
		})
	}

	public getFlowmeterDetail(id: number): Promise<IFlowWateringViewModel[] | CMError> {

		return this.get(this._urls.detail(id), (data: IFlowWateringViewModel[]): IFlowWateringViewModel[] => {
			if (data) {
				return data;
			} else {
				return [];
			}
		});
	}

	public getFiles(): Promise<IFlowMeterTableRow[]> {
		return this.get(this._urls.files, (models: IFlowMeterPlantingListViewModel[]): IFlowMeterTableRow[] => {
			let results: IFlowMeterTableRow[];

			results = [];

			for (let model of models) {
				results.push({
					Id: model.Id,
					RanchName: model.RanchName,
					PlantingName: model.PlantingName,
					HarvestDate: DateUtility.DotNetToDate(model.HarvestDate),
					FileName: model.FileName
				});
			}

			return results;
		});
	}

	public import(plantingId: number, shouldImportAll: boolean): Promise<CMError | SuccessResponse> {
		let options: HttpServicePostOptions;

		options = {
			url: this._urls.import(plantingId),
			body: {
				ShouldImportAll: shouldImportAll
			},
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,
		}

		return this.httpService.put(options);
	}

	/**
	 * Check to see if the flowmeter file exists
	 * @param fileName flowmeter file name
	 * @returns true if the flowmeter file exists
	 */
	public isFilenameValid(fileName: string): Promise<boolean> {
		let params: HttpParams;

		params = new HttpParams().set('fileName', '' + fileName);

		return this.get(this._urls.isFilenameValid, (data: boolean): boolean => {
			return data;
		}, params);
	}

	/**
	 * Get flowmeter configuration settings
	 * @param plantingId
	 */
	public getSettings(plantingId: number): Promise<FlowmeterSettings> {
		return this.get(
			this._urls.settings.get(plantingId),
			(json: FlowmeterSettings) => {
				return json
			}
		);
	}

	/**
	 * Update planting with flowmeter settings
	 * @param plantingId
	 * @param settings
	 * @returns
	 */
	public saveSettings(plantingId: number, settings: FlowmeterSettings): Promise<void> {
		let options: HttpServicePostOptions;

		options = {
			url: this._urls.settings.update(plantingId),
			body: settings,
			isWebAPI: true,
			shouldBypassServerErrorAlert: false,
		}

		return this.httpService.put(options);
	}
}
