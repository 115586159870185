<div class="bk-admin__page">
	<form [formGroup]=form
		*ngIf="form">
		<h2>CropManage ET Calculator</h2>

		<h4>Crop Information</h4>
		<mat-form-field
			*ngIf="commodityTypes" 
			appearance="fill"
			class="mat-form-field--one-line mat-form-field--short">

			<mat-label>{{'Commodity' | translate }}</mat-label>

			<mat-select required
				formControlName="CommodityTypeId">

				<mat-option *ngFor="let commodityType of commodityTypes"
					[value]="commodityType.Id">
					{{commodityType.Name | translate}}
				</mat-option>

			</mat-select>

			<mat-error *ngIf="f.CommodityTypeId.invalid && (f.CommodityTypeId.dirty || f.CommodityTypeId.touched)">

				{{'Cannot be empty. Please select an option.' | translate }}
			</mat-error>

		</mat-form-field>

		<mat-form-field
			*ngIf="cropTypes && cropTypes.length > 0" 
			appearance="fill"
			class="mat-form-field--one-line mat-form-field--short">

			<mat-label>{{'CropType' | translate }}</mat-label>

			<mat-select required
				formControlName="CropTypeId">

				<mat-option *ngFor="let cropType of cropTypes"
					[value]="cropType.Id">
					{{ cropType.Name }}
				</mat-option>

			</mat-select>

			<mat-error *ngIf="f.CropTypeId.invalid && (f.CropTypeId.dirty || f.CropTypeId.touched)">
				{{'Cannot be empty. Please select an option.' | translate }}
			</mat-error>

		</mat-form-field>

		<mat-form-field *ngIf="isTree"
			appearance="fill"
			class="mat-form-field--one-line mat-form-field--short">

			<mat-label>{{'Age of Crop' | translate }}</mat-label>

			<mat-select
				required
				formControlName="AgeOfCrop">

				<mat-option *ngFor="let ageOfCropOption of ageOfCropOptions"
					[value]="ageOfCropOption.key">
					{{ ageOfCropOption.value }}
				</mat-option>

			</mat-select>

			<mat-error *ngIf="f.AgeOfCrop.invalid && (f.AgeOfCrop.dirty || f.AgeOfCrop.touched)">

				{{'Age of crop is required' | translate }}
			</mat-error>

		</mat-form-field>

		<div class="instruction-container">
			<h4>Crop Season Dates</h4>
			<p>Enter the start and end dates of the crop's growing season.</p>
		
			<ul>
				<li><b>Date Range:</b> Choose start and end dates
					that are less than 366 days apart (about one year).</li>
				<li><b>Select recent dates (max 2 years old):</b> Choose dates that
					are before today but no older than two years ago.</li>
			</ul>
		</div>
		<mat-form-field appearance="fill"
			class='bk-mat-date-field bk-mat-date-field--first'>
			<mat-label>{{ 'Start Date' | translate }}</mat-label>
			<input matInput [matDatepicker]="wetDatePicker"
				formControlName="WetDate"
				required
				[min]="wetDateRange.minDate"
				[max]="wetDateRange.maxDate">
			<mat-datepicker-toggle matSuffix [for]="wetDatePicker"></mat-datepicker-toggle>
			<mat-datepicker #wetDatePicker></mat-datepicker>
			<mat-error *ngIf="f.WetDate.invalid && (f.WetDate.dirty || f.WetDate.touched)">
				{{'Planting Event Date Error Text' | translate }}
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="fill"
			class='bk-mat-date-field'>
			<mat-label>{{ 'End Date' | translate }}</mat-label>
			<input matInput [matDatepicker]="harvestDatePicker"
				formControlName="HarvestDate"
				required
				[min]="harvestDateRange.minDate"
				[max]="harvestDateRange.maxDate">
			<mat-datepicker-toggle matSuffix [for]="harvestDatePicker"></mat-datepicker-toggle>
			<mat-datepicker #harvestDatePicker></mat-datepicker>
			<mat-error *ngIf="f.HarvestDate.invalid && (f.HarvestDate.dirty || f.HarvestDate.touched)">
				{{'Planting Event Date Error Text' | translate }}
			</mat-error>
		</mat-form-field>

		<h4>Location</h4>
		<p>
			Click the location of your planting on the map. CropManage will use your location to get accurate weather and
			soil data used for ET calculation.
		</p>
		<agm-map *ngIf="weatherStations && weatherStations.length > 0"
			#map
			(mapClick)="clickMap($event)"
			(mapReady)="mapReady($event)"
			[zoom]="10"
			[mapTypeId]="'hybrid'"
			[fullscreenControl]="true">

			<agm-marker *ngIf="f.Latitude && f.Longitude"
				[iconUrl]="'../assets/images/icons/-v2.png'"
				[latitude]="f.Latitude.value"
				[longitude]="f.Longitude.value">
			</agm-marker>

			<map-control #mapControl></map-control>
		</agm-map>

		<p *ngIf="weatherStations && f.WeatherStationId.value">

			<strong>Nearest Weather Station: </strong>{{ weatherStations[0].Name }}
			<br>
			<strong>Distance: </strong> {{ weatherStations[0].Distance * 0.621371 | number:'1.2-2' }} miles
			<br>
		</p>

		<br>
		<button mat-button
			[disabled]="!form.valid"
			class="bk-button bk-button--medium bk-dialog__button--primary"
			(click)="calculate()">

			{{ (isSaving ? 'Calculating' : 'Calculate') | translate }}
			<img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
		</button>
		<br>
		<br>
	</form>
</div>