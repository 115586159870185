import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateUtility } from '../../classes/dateUtility';
import { PlantingService } from '../planting-settings/service';
import { IAvailableWater, IHighChartCustomSeries } from './interfaces';
import * as Highcharts from 'highcharts';
import highChartExportOptions from '../shared/highchartsExportOptions';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RanchService } from '../ranch-settings/service';
import { IPlanting } from '../planting-settings/interfaces';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FormValidation } from '../../classes/formValidation';
import { CustomSaturationHelp } from './custom-saturation-help';
import { SharedUpdateService } from '../shared/dialogs/update.service';
import { PersistentDatabase } from '../../services/persistent-database';

export type AvailableWaterChartFormFields = 'StartDate' | 'InitialSaturation';

@Component({
	moduleId: module.id,
	selector: 'available-water-chart',
	templateUrl: 'available-water-chart.html',
	styleUrls: ['available-water-chart.scss']
})

export class AvailableWaterChartComponent implements OnInit, OnDestroy {
	public plantingName: string;
	public isLoaded = false;
	public form: FormGroup;
	public maxDate: Date;
	public minDate: Date;
	public isAdmin = true;

	@ViewChild('chartContainer', { static: true }) private _chartContainer: ElementRef;
	@ViewChild('etChartContainer', { static: true }) private _ETChartContainer: ElementRef;
	@ViewChild('kcChartContainer', { static: false }) private _KCChartContainer: ElementRef;
	@ViewChild('ksChartContainer', { static: true }) private _KSChartContainer: ElementRef;
	@ViewChild('rootDepthChartContainer', { static: false }) private _rootDepthChartContainer: ElementRef;
	private _chartData: Highcharts.IndividualSeriesOptions[];
	private _ETChartData: Highcharts.IndividualSeriesOptions[]; // for the ET chart
	private _KCChartData: Highcharts.IndividualSeriesOptions[];
	private _rootDepthChartData: Highcharts.IndividualSeriesOptions[];
	private _KsChartData: Highcharts.IndividualSeriesOptions[];
	private _planting: IPlanting;
	private _soilWaterColor = 'rgb(203, 221, 237)';
	private _maxRainApplied: number; // maximum applied water /rainfall value, used to scale the chart
	private _isStressEnabled: boolean;

	private _charts: {
		soilWater: Highcharts.ChartObject,
		et: Highcharts.ChartObject,
		kc: Highcharts.ChartObject,
		ks: Highcharts.ChartObject,
		rootDepth: Highcharts.ChartObject
	};

	private _dialogHideClass = 'bk-dialog--hidden';
	private _maxSAW: number;
	private _subscriptions$: Subject<boolean>;

	public get f(): { [key in AvailableWaterChartFormFields]: AbstractControl } {
		return this.form.controls as { [key in AvailableWaterChartFormFields]: AbstractControl };
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: { lotPlantingId: number, name: string },
		private _dialog: MatDialog,
		private _dialogRef: MatDialogRef<AvailableWaterChartComponent>,
		private _fb: FormBuilder,
		private _ranchService: RanchService,
		private _service: PlantingService,
		private _persistentDatabase: PersistentDatabase,
		private _sharedUpdateService: SharedUpdateService
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._configureClickOutside(this._subscriptions$, this._dialogRef);

		if (!this._data) {
			throw new Error('data is empty');
		}

		this._planting = this._ranchService.plantings.getPlantingById(this._data.lotPlantingId);
		this.plantingName = this._data.name;

		this.isAdmin = (this._persistentDatabase.user.Roles &&
			this._persistentDatabase.user.Roles.length > 0 &&
			this._persistentDatabase.user.Roles[0].Id === 1) ? true : false;
			this._service.getCustomSaturationSettings(this._data.lotPlantingId).then(response => {
				this._initializeForm();

			if (response.SaturationDate !== null) {
					this.f.StartDate.setValue(DateUtility.DotNetToDate(response.SaturationDate));
			} else {
				this.f.StartDate.setValue(this.minDate);
				}

			if (response.PercentSaturation !== null) {
					this.f.InitialSaturation.setValue(response.PercentSaturation * 100);
			} else {
				this.f.InitialSaturation.setValue(75);
				}

			this._isStressEnabled = response.IsStressEnabled;

			this._service.getAvailableWaterChart(this._data.lotPlantingId).then(chartResponse => {
				this._loadChartData(chartResponse);

			this.isLoaded = true;
		});
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public cancel(): void {
		this._dialogRef.close();
	}

	public onUpdate(): void {
		if (this.form.invalid) {
			return;
		}

		if (!this.isLoaded) {
			return;
		}

		this.isLoaded = false;

		this._service.refreshAvailableWaterChart(this._planting.Id,
			this.f.StartDate.value, this.f.InitialSaturation.value).then(() => {
				this._service.getAvailableWaterChart(this._planting.Id).then((response) => {
					this._loadChartData(response);
					this.isLoaded = true;
				});
			});
		// recalculate available water
		// refetch graph
	}

	/**
	 * Reflow highCharts inside tabs. Otherwise they create horizontal scrollbars
	 * @param $event
	 */
	public redrawGraph($event: MatTabChangeEvent): void {
		if (!this._charts || !this._charts.soilWater ||
			!this._charts.et || !this._charts.kc || !this._charts.rootDepth) {

			return;
		}

		switch ($event.index) {
			case 0:
				this._charts.soilWater.reflow();
				break;
			case 1:
				this._charts.et.reflow();
				break;
			case 2:
				this._charts.kc.reflow();
				break;
			case 3:
				this._charts.ks.reflow();
				break;
			case 4:
				this._charts.rootDepth.reflow();
				break;
		}
	}

	/***
	 * Method visible in the template for opening
	 * the confirmation dialog
	 */
	public onCustomSaturationHelpOpen(): void {

		this._dialogRef.addPanelClass(this._dialogHideClass);

		this._dialog.open(CustomSaturationHelp, {
			width: '600px',
			disableClose: true,
		});

		this._sharedUpdateService.cancel$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this._dialogRef.removePanelClass(this._dialogHideClass);
		});
	}

		/***
	 * Hide the parent dialog while child dialogs are open
	 */
		private _hide(): void {
			this._dialogRef.addPanelClass(this._dialogHideClass);
		}

	private _configureClickOutside(s: Subject<boolean>, d: MatDialogRef<AvailableWaterChartComponent>): void {
		// custom click outside behavior
		d.disableClose = true;

		d.backdropClick().pipe(takeUntil(s)).subscribe(() => {
			d.close();
		});
	}

	private _convertToGraphData(records: IAvailableWater[]): void {

		let temp: {
			calculated: Highcharts.AreaChartSeriesOptions,
			rain: IHighChartCustomSeries,
			rainKs: IHighChartCustomSeries,
			et: IHighChartCustomSeries,
			waterApplied: IHighChartCustomSeries,
			waterAppliedKs: IHighChartCustomSeries,
			rootDepth: IHighChartCustomSeries,
			kc: IHighChartCustomSeries,
			potentialPAW: Highcharts.LineChartSeriesOptions,
			recommendation: IHighChartCustomSeries,
			ETe: IHighChartCustomSeries,
			ks:	IHighChartCustomSeries,
			ETo: IHighChartCustomSeries,
			kcMax: IHighChartCustomSeries,
			Dr: IHighChartCustomSeries
		};

		this._maxRainApplied = 0;

		temp = {
			calculated: {
				name: 'Estimated Soil Water Deficit',
				visible: true,
				type: 'area',
				data: [],
				color: this._soilWaterColor,
				zIndex: 1,
				marker: {
					symbol: 'circle',
					// 'circle', 'square','diamond', 'triangle' and 'triangle-down'.
				}
			},
			recommendation: {
				name: 'Recommendation',
				type: 'line',
				visible: false,
				data: []
			},
			rain: {
				name: 'Rainfall',
				type: 'column',
				visible: true,
				color: 'rgb(67, 67, 72)',
				data: [],
			},
			rainKs: {
				name: 'Rainfall',
				type: 'column',
				visible: true,
				color: 'rgb(67, 67, 72)',
				data: [],
				yAxis: 1
			},
			et: {
				name: 'ETc',
				type: 'line',
				visible: true,
				data: [],
				tooltip: {
					valueDecimals: 4
				}
			},
			ETe: {
				name: 'ETe',
				type: 'line',
				visible: false,
				data: [],
				tooltip: {
					valueDecimals: 4
				}
			},
			ETo: {
				name: 'ETo',
				type: 'line',
				visible: false,
				data: [],
				tooltip: {
					valueDecimals: 4
				}
			},
			Dr: {
				name: 'Allowable Depletion',
				type: 'line',
				visible: true,
				zIndex: 2,
				data: [],
				tooltip: {
					valueDecimals: 2
				}
			},
			waterApplied: {
				name: 'Applied Water',
				type: 'column',
				visible: true,
				zIndex: 2,
				color: 'rgb(144, 237, 125)',
				data: []
			},
			waterAppliedKs: {
				name: 'Applied Water',
				type: 'column',
				visible: true,
				color: 'rgb(144, 237, 125)',
				data: [],
				yAxis: 1
			},
			rootDepth: {
				name: 'Root Depth',
				type: 'line',
				visible: true,
				data: [],
				tooltip: {
					valueSuffix: ' ft'
				}
			},
			kc: {
				name: 'Kc',
				type: 'line',
				visible: true,
				data: [],
				tooltip: {
					valueSuffix: ''
				}
			},
			kcMax: {
				name: 'KcMax',
				type: 'line',
				visible: true,
				data: [],
				tooltip: {
					valueSuffix: ''
				}
			},
			ks: {
				name: 'Ks',
				type: 'area',
				visible: true,
				data: [],
				yAxis: 0,
				color: this._soilWaterColor,
				tooltip: {
					valueSuffix: ''
				}
			},
			potentialPAW: {
				name: 'PAW Deficit',
				type: 'line',
				zIndex: 2,
				dashStyle: 'dash',
				visible: true,
				color: 'rgb(247, 163, 92)',
				data: [],
				marker: {
					symbol: 'triangle'
				}
			}
		}

		this._chartData = [];
		this._ETChartData = [];
		this._KCChartData = [];
		this._KsChartData = [];
		this._rootDepthChartData = [];

		if (!records || records.length === 0) {
			return;
		}

		for (let record of records) {
			let d: number;

			d = DateUtility.DateToUTC(record.Date);

			temp.calculated.data.push([d, record.Calculated - record.Potential]);
			temp.rain.data.push([d, record.Rainfall]);
			temp.rainKs.data.push([d, record.Rainfall]);
			temp.et.data.push([d, record.ETc]);
			temp.ETe.data.push([d, record.ETe]);
			temp.ETo.data.push([d, record.ETo]);
			temp.Dr.data.push([d, record.Dr]);
			temp.potentialPAW.data.push([d, 0 - record.PotentialPAW]);
			temp.kcMax.data.push([d, record.KcMax]);
			temp.waterApplied.data.push([d, record.WaterApplied]);
			temp.waterAppliedKs.data.push([d, record.WaterApplied]);
			temp.rootDepth.data.push([d, record.RootDepth]);
			temp.kc.data.push([d, record.Kc]);
			temp.ks.data.push([d, (1 - record.Ks) * 100]);

			this._maxRainApplied = Math.max(this._maxRainApplied, record.Rainfall, record.WaterApplied);
		}

		this._ETChartData.push(temp.et);
		this._ETChartData.push(temp.ETe);
		this._ETChartData.push(temp.ETo);

		this._KCChartData.push(temp.kc);

		if (this.isAdmin) {

			this._KCChartData.push(temp.kcMax);
		}

		this._KsChartData.push(temp.ks);
		this._KsChartData.push(temp.rainKs);
		this._KsChartData.push(temp.waterAppliedKs);

		this._rootDepthChartData.push(temp.rootDepth);

		this._chartData.push(temp.calculated);
		this._chartData.push(temp.rain);
		this._chartData.push(temp.waterApplied);

		if (this._isStressEnabled) {
			this._chartData.push(temp.Dr);
		} else {
		this._chartData.push(temp.potentialPAW);
	}
	}

	private _drawGraph(chart: ElementRef, data: IHighChartCustomSeries[], yAxis: Highcharts.AxisOptions[]): Highcharts.ChartObject {

		let options: Highcharts.Options;
		let myChart: Highcharts.ChartObject;

		if (!chart) {
			return;
		}

		options = {
			chart: {
			// type: 'line',
			},
			plotOptions: {
				area: {
					fillColor: 'rgba(2, 0, 255, 0.1)', // unfilled area
					negativeFillColor: this._soilWaterColor, // Solid fill color for the soil water
					threshold: null
				},
				series: {
					marker: {
						enabled: false,
					},
					events: {
						/** modify yaxis depending on active legend */
						legendItemClick: (e: MouseEvent) => {
							let name: string;

							name = (e.target as HTMLInputElement).name;

							if (name === 'Estimated Soil Water') {
								if (myChart.series[0].visible) {
									myChart.yAxis[0].update({
										max: null
									});
								} else if (!myChart.series[0].visible) {
									myChart.yAxis[0].update({
										max: this._maxSAW
									})
								}
							}
						}
					}
				}
			},
			title: {
				text: null,
			},
			legend: {
				title: {
					text: null,
				}
			},
			tooltip: {
				backgroundColor: '#FFF',
				borderColor: '#FFF',
				valueDecimals: 2,
				valueSuffix: ' in.',
				// formatter: tooltipFormatter
			},
			xAxis: {
				type: 'datetime',
				gridLineWidth: 1,
				plotLines: [
					{
						color: 'red',
						dashStyle: 'longdashdot',
						value: this._planting.WetDate.getTime(),
						width: 2,
						zIndex: 5,
						label: {
							verticalAlign: 'top',
							y: -10,
							text: this._planting.WetDate.toDateString(),
							style: {
								fontWeight: 'bold',
								fontSize: '12px'
							}
						}
					}
				]
			},
			yAxis: yAxis,
			series: data,
			exporting: highChartExportOptions
		}

		myChart = Highcharts.chart(chart.nativeElement, options);
		return myChart;
	}

	private _findMax(records: IAvailableWater[]): number {
		let result = 0;


		if (!records || records.length === 0) {
			return result;
		}

		return records[0].Potential;
	}

	/**
	 * Use the earliest record date as the default start date, to account for
	 * various vegetable crop types
	 * @param records
	 * @returns
	 */
	private _getDefaultStartDate(records: IAvailableWater[]): Date {
		if (!records || records.length === 0) {
			return null;
		}

		return records[0].Date
	}

	/**
	 * For all crops, the user is allowed to choose a custom start date before
	 * wet date
	 * @param wetDate
	 * @returns
	 */
	private _getMinStartDate(wetDate: Date): Date {
		let result: Date;

		result = moment(wetDate).subtract(2, 'months').toDate();

		return result;
	}

	private _initializeForm(): void {

		this.minDate = this._getMinStartDate(this._planting.WetDate);
		this.maxDate = moment(this._planting.WetDate).add(-1, 'days').toDate();

		this.form = this._fb.group({
			StartDate: [null,
				[Validators.required, FormValidation.minDateValidator(this.minDate, true),
				FormValidation.maxDateValidator(this.maxDate, true)]],
			InitialSaturation: [null, [Validators.required, Validators.min(0), Validators.max(100)]]
		});
	}

	private _loadChartData(response: IAvailableWater[]): void {
		this._maxSAW = this._findMax(response);
		this._convertToGraphData(response);

		this._charts = {
			soilWater: this._drawGraph(this._chartContainer, this._chartData, [{
				title: {
					text: 'Deficit Inches'
				},
				startOnTick: false,
				endOnTick: false
			}]),
			et: this._drawGraph(this._ETChartContainer, this._ETChartData, [
				{
					title: {
						text: 'Inches'
					},
					startOnTick: false,
					endOnTick: false
				}
			]),
			kc: this._drawGraph(this._KCChartContainer, this._KCChartData, [
				{
					title: {
						text: 'Kc',
					},
				},
			]),
			ks: this._drawGraph(this._KSChartContainer, this._KsChartData, [
				{
					title: {
						text: 'Ks (%)',
					},
					opposite: false,
					max: 100,
					min: 0
				},
				{
					title: {
						text: 'Inches'
					},
					opposite: true,
					max: this._maxRainApplied,
					min: 0
				}
			]),
			rootDepth: this._drawGraph(this._rootDepthChartContainer, this._rootDepthChartData, [
				{
					title: {
						text: 'Ft'
					},
					reversed: true,
				},
			])
		}
	}
}
