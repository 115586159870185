<div class="bk-dialog bk-modal__large">
    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">

        &times;
    </button>
    <h4 mat-dialog-title>
         {{'View' | translate }} {{'Weather Station' | translate }}
    </h4>

    <div mat-dialog-content>
        <div class="weather-station-container">
            <div class="left-content-container bk-modal__left bk-weather-stations-left
				bk-weather-stations__left--edit-mode">
				<div class="bk-weather-station" *ngIf="station">
					<div class="bk-weather-station__name bk-bold">
						{{station.Name}} (#{{station.External_Id}})
					</div>
					<div class="bk-weather-station__name">
						{{station.NearestCity}}, {{station.County}} {{'County' | translate }}
					</div>
					<div class="bk-weather-station__name">
						{{station.Distance}} {{'miles away' | translate }}
					</div>
				</div>
            </div>
            <div class="bk-modal__right bk-weather-stations-right">
                <div class="bk-modal__right-instructions">
                    <div class="bk-marker bk-marker--ranch">
                        <svg-ranch-marker></svg-ranch-marker>
                    </div>
                    {{'Ranch Location' | translate }}
                    <div class="bk-marker bk-marker--selected">
                        <svg-station-location></svg-station-location>
                    </div>
                    {{'Weather Station' | translate }}
                </div>
                <div class="map-container bk-modal__map-container" *ngIf="station">
                    <agm-map
                        [latitude]="station.Latitude"
                        [longitude]="station.Longitude"
                        [mapTypeId]="'hybrid'"
                        [fullscreenControl]="true">

                        <agm-marker *ngIf="station"
                            [iconUrl]="getStationMarker(station)"
                            [latitude]="station.Latitude"
                            [longitude]="station.Longitude"
                            [label]="getStationId(station.External_Id)">
                        </agm-marker>

                        <agm-marker *ngIf="ranchLocation"
                            [iconUrl]="'../assets/images/icons/map_marker-ranch-v2.png'"
                            [latitude]="ranchLocation.Latitude"
                            [longitude]="ranchLocation.Longitude">
                        </agm-marker>
                        
                        <map-control></map-control>
                    </agm-map>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
		<button class="bk-button bk-button--medium bk-button--text-only
			bk-button--float-left"
			(click)="onDelete()">
			{{'Remove' | translate }} {{'Weather Station' | translate }}
		</button>
		<button class="bk-button bk-button--medium"
			(click)="close()">
			{{'Close' | translate }}
		</button>
    </div>
</div>