import { Component, ViewChild, OnInit, EventEmitter, Output, ElementRef, Inject, AfterViewInit, OnDestroy } from '@angular/core';

import { FlowMeter, chartTypes } from './flowMeter';
import { FlowmeterService } from './service';
import { CMError } from '../../interfaces/interfaces';
import { IFlowWateringViewModel, IFlowmeterSummaryViewModel } from './interfaces';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlowmeterSettingsComponent } from './flowmeter-settings';
import { FlowmeterChartUpdateService } from './flowmeter-chart.update.service';

declare var $: JQueryStatic;

@Component({
	moduleId: module.id,
	selector: 'flowmeter-data',
	templateUrl: 'main.html'
})

export class FlowmeterChartDialog implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('highchartsContainer', { static: false }) private _highchartsContainer: ElementRef;
	@ViewChild('hiddenHandle', { static: false }) public hiddenHandle: ElementRef;
	// used to grab the DOM to send it to Flots - can't just use IDs because there are multiple DOMs with the same ID

	@Output()
	onModalClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	onModalOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

	// used to hide the graph from view until data is loaded
	public loadingGraph = true;

	public plantingId: number;
	public plantingName: string;
	public startDate: Date;
	public endDate: Date;
	public flowmeterData: IFlowWateringViewModel[] = new Array();
	public flowmeterSummary: IFlowmeterSummaryViewModel[];
	public flowmeter: FlowMeter;

	public chartTypes = chartTypes;
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _dialog: MatDialog,
		private _dialogRef: MatDialogRef<FlowmeterChartDialog>,
		@Inject(MAT_DIALOG_DATA) private _data: { plantingId: number, plantingName: string, startDate: Date,
			endDate: Date, chartType: chartTypes },
		private flowmeterService: FlowmeterService,
		private flowmeterChartUpdateService: FlowmeterChartUpdateService) { }

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this.flowmeter = new FlowMeter(this.flowmeterService);

		if (this._data) {
			this.plantingId = this._data.plantingId;
			this.plantingName = this._data.plantingName;
			this.startDate = this._data.startDate;
			this.endDate = this._data.endDate;
			this.flowmeter.chartType = this._data.chartType;
		}

		this._dialogRef.backdropClick()
			.pipe(takeUntil(this._subscriptions$)).subscribe(() => { // custom click outside

			this._close();
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	/**
	 * We reference hiddenHandle after view init since its not loaded in ngOnInit
	 */
	public ngAfterViewInit(): void {

		this.loadSummary(this.plantingId);
	}

	public close(): void {
		this._close();
	}

	private _close(): void {
		this._dialogRef.close();
		this.flowmeterChartUpdateService.dialogClosed();
	}

	public openSettings(): void {
		this._dialogRef.close();

		this._dialog.open(FlowmeterSettingsComponent, {
			disableClose: true,
			width: '695px',
			data: {
				lotPlantingId: this.plantingId,
			}
		});
	}

	public loadSummary(plantingId: number): void {

		if (!this.hiddenHandle) {
			return;
		}

		if (!this.flowmeter) {
			throw new Error('flowmeter object not loaded');
		}

		if (!plantingId) {
			throw new Error('plantingId is null');
		}

		this.flowmeter.chartType = chartTypes.summary;

		this._refreshGraph(plantingId, $(this.hiddenHandle.nativeElement));
		this._getFlowmeterSummary(plantingId);
	}

	/**
	 * Called by child view FlowmeterSummaryTableComponent - triggered when user clicks on a table row
	 *
	 * @param flowWateringId
	 */
	public loadFlowmeterDetails(flowWateringId: number) {

		if (!this.hiddenHandle) {
			return;
		}

		this.flowmeter.chartType = chartTypes.detail;
		this._refreshGraph(flowWateringId, $(this.hiddenHandle.nativeElement));

		this._getFlowmeterDetail(flowWateringId);
	}

	public goBackToSummary() {
		this.loadSummary(this.plantingId);
	}

	/**
	 * Get flow data associated with a specific flow watering record
	 *
	 * @param flowWateringId
	 */
	private _getFlowmeterDetail(flowWateringId: number): void {

		this.flowmeterService.getFlowmeterDetail(flowWateringId)
			.then((res) => {
				let error: CMError;
				let data: IFlowWateringViewModel[];

				if (!res) {
					return;
				}

				error = res as CMError;

				if (error.message) {
					return;
				}

				data = res as IFlowWateringViewModel[];

				this.flowmeterData = data;
			});
	}

	private _getFlowmeterSummary(id: number): void {

		this.flowmeterService.getFlowmeterSummary(id)
			.then((res) => {
				let error: CMError;
				let response: IFlowmeterSummaryViewModel[];

				if (!res) {
					return;
				}

				error = res as CMError;
				response = res as IFlowmeterSummaryViewModel[];

				if (error.message) {
					return;
				}

				this.flowmeterSummary = response;
			}
		);
	}

	/**
	 * @param id Warning: id can be either planting ID or flow watering ID
	 * @param $dom
	 * @param startDate
	 * @param endDate
	 */
	private _refreshGraph(id: number, $dom: JQuery, startDate?: Date, endDate?: Date): void {
		this.loadingGraph = true;

		if (startDate && endDate) {
			this.flowmeter.Initialize(id, this._highchartsContainer, startDate, endDate)
				.then(() => this.loadingGraph = false);
		} else {
			this.flowmeter.Initialize(id, this._highchartsContainer)
				.then(() => this.loadingGraph = false);
		}
	}
}
