import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SharedUpdateService } from './update.service';

@Component({
	moduleId: module.id,
	selector: 'shared-keep-discard-dialog',
	templateUrl: 'keep-discard-dialog.html'
})

export class SharedKeepDiscardDialog implements OnInit {

	public constructor(
		private _updateService: SharedUpdateService,
		private _dialogRef: MatDialogRef<SharedKeepDiscardDialog>,
	) {

	}

	ngOnInit(): void {
	}

	public keep($event: MouseEvent): void {
		$event.stopPropagation();
		$event.preventDefault();

		this._dialogRef.close();
		this._updateService.keep();
	}

	public discard($event: MouseEvent) {
		$event.stopPropagation();
		$event.preventDefault();

		this._dialogRef.close();
		this._updateService.discard();
	}
}
