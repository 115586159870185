import { Injectable } from '@angular/core';

import { HttpService, HttpServicePostOptions, HttpServiceGetOptions } from '../../services/http.service';

import { ICanopyTableData, INewCanopyData } from './canopyCurve.interface';
import { ICanopyData, ISimsGraphDataModel, ICanopyService, SimsDataModel } from './interfaces';
import { HttpParams } from '@angular/common/http';
import { CMError, SuccessResponse } from '../../interfaces/interfaces';
import { of } from 'rxjs';
import { Canopy } from './canopy';

@Injectable()
export class CanopyService implements ICanopyService {

	private _urls = {
		simsData: (plantingId: number) => `/v2/plantings/${plantingId}/sims-data/summary.json`,
		simsGraph: (plantingId: number) => `/v2/plantings/${plantingId}/simis-data/graph.json`,
		update: (plantingId: number) => `/v2/plantings/${plantingId}/crop-types/canopy-coefficients.json`,
		table: {
			get: (plantingId: number) => `/v2/plantings/${plantingId}/simis-data/table.json`,
			create: (plantingId: number) => `/v2/plantings/${plantingId}/canopy-cover-events.json`,
			delete: (eventId: number) => `/v2/canopy-cover-events/${eventId}.json`
		}
	}

	constructor(private _httpService: HttpService) { }

	public simsData(plantingId: number): Promise<SimsDataModel> {

		let options: HttpServiceGetOptions = {
			url: this._urls.simsData(plantingId),

			callback: (response: SimsDataModel) => {

				if (!response) {
					return null;
				}

				return response;
			},
			isWebAPI: true,
		}

		return this._httpService.get(options);
	}

	public simsGraphData(canopy: Canopy): Promise<ISimsGraphDataModel> {

		let params: HttpParams;
		let model: ICanopyData;

		if (!canopy || !canopy.form || canopy.form.invalid) {
			return of(null).toPromise();
		}

		model = canopy.form.value as ICanopyData;

		params = new HttpParams()
			.set('CanopyGMax', model.CanopyGMax.toString())
			.set('MaxFraction', model.MaxFraction.toString())
			.set('CanopyA', model.CanopyA.toString())
			.set('CanopyB', model.CanopyB.toString())
			.set('CanopyE', model.CanopyE.toString())
			.set('CanopyF', model.CanopyF.toString())
			.set('CanopyMin', model.CanopyMin.toString());

		let options: HttpServiceGetOptions = {
			url: this._urls.simsGraph(canopy.PlantingId),

			callback: (response: ISimsGraphDataModel) => {
				return response;
			},
			searchParams: params,
			isWebAPI: true,
		}

		return this._httpService.get(options);
	}

	public update(model: ICanopyData, plantingId: number): Promise<ICanopyData> {

		if (!model) {
			return of(null).toPromise();
		}

		let options: HttpServicePostOptions = {
			url: this._urls.update(plantingId),
			callback: (response: ICanopyData) => {
				return response;
			},
			body: model,
			isWebAPI: true
		}

		return this._httpService.put(options);
	}

	public getTableCustomSettings(plantingId: number, canopyGMax: number, maxFraction: number, canopyA: number,
		canopyB: number, canopyE: number, canopyF: number, canopyMin: number): Promise<ICanopyTableData> {

		let params: HttpParams;

		params = new HttpParams()
			.set('CanopyGMax', '' + canopyGMax)
			.set('MaxFraction', '' + maxFraction)
			.set('CanopyA', '' + canopyA)
			.set('CanopyB', '' + canopyB)
			.set('CanopyE', '' + canopyE)
			.set('CanopyF', '' + canopyF)
			.set('CanopyMin', canopyMin.toString());

		let options: HttpServiceGetOptions = {
			url: this._urls.table.get(plantingId),
			callback: (response: ICanopyTableData) => {
				return response
			},
			searchParams: params,
			isWebAPI: true,
		}

		return this._httpService.get(options);
	}

	public createTableEntry(plantingId: number, newCanopyData: INewCanopyData): Promise<CMError> {

		let options: HttpServicePostOptions = {
			url: this._urls.table.create(plantingId),
			callback: (response: CMError ) => {
				return response
			},
			body: newCanopyData,
			isWebAPI: true,
		}

		return this._httpService.post(options);
	}

	public deleteTableEntry(simsId: number): Promise<SuccessResponse> {

		let options: HttpServiceGetOptions = {
			url: this._urls.table.delete(simsId),
			callback: (response: SuccessResponse): SuccessResponse => {
				return response
			},
			isWebAPI: true,
		}

		return this._httpService.delete(options);
	}
}
