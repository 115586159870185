<table class="bk-event__table bk-flowmeter">
	<thead>
		<tr>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Start' | translate }}</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'End' | translate }}</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Average Flow Rate' | translate }} ({{'gpm' | translate }})</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Gallons' | translate }}</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Time' | translate }}</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Applied Flowmeter Water' | translate }} ({{'in' | translate }})</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Application Rate' | translate }} ({{'in/hr' | translate }})</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Flowmeter Area' | translate }} ({{'acres' | translate }})</td>
		</tr>
	</thead>
	<tbody>
		<tr class="bk-event-table__row" *ngFor="let data of flowmeterData" (click)="loadDetails(data.Id)">
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.StartDate}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.EndDate}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.AvgFlow}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.Gallons}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.Time}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.Applied}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.AppRate}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.SprinklerArea > 0 ? data.SprinklerArea : 'N/A'}}</td>
		</tr>
	</tbody>
</table>